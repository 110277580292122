<template>
  <div class="formBox">
    <div class="formTitle">
      填写真实信息，我们将有工作人员在1-2个工作日内联系您。
    </div>
    <el-form :model="formLabelAlign" ref="formLabelAlign">
      <el-form-item
        prop="company"
        :rules="[{ required: true, message: '公司名称不能为空' }]"
      >
        <el-input
          placeholder="公司名称"
          v-model="formLabelAlign.company"
        ></el-input>
      </el-form-item>
      <el-form-item
        prop="name"
        :rules="[{ required: true, message: '姓名不能为空' }]"
      >
        <el-input placeholder="姓名" v-model="formLabelAlign.name"></el-input>
      </el-form-item>
      <el-form-item
        prop="phone"
        :rules="[{ required: true, message: '手机号不能为空' }, { pattern: /^1[3|5|7|8|9]\d{9}$/, message: '请输入正确的号码格式'}]"
      >
        <el-input
          placeholder="手机号"
          v-model="formLabelAlign.phone"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('formLabelAlign')"
          >提交信息</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "form-submit",
  data() {
    return {
      formLabelAlign: {
        company: "",
        name: "",
        phone: "",
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$message({
            message: "提交成功！",
            type: "success",
          });
          this.$refs[formName].resetFields();
        } else {
          this.$message.error("提交失败！");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.formBox >>> .el-form-item__content {
  text-align: center;
}
.formBox >>> .el-input__inner {
  width: 60%;
  border-radius: 40px;
  height: 60px;
  padding: 0 30px;
}
.formBox >>> .el-form-item {
  margin-bottom: 30px;
}
.formBox >>> .el-button {
  width: 36%;
  padding: 20px;
  border-radius: 40px;
  font-size: 20px;
  margin-top: 30px;
  box-shadow: 8px 8px 5px #a3b7f4;
  background: #668fff;
  border-color: #668fff;
}
.formTitle {
  font-size: 28px;
  color: #818186;
  padding: 100px 0;
  width: 100%;
  text-align: center;
}
.formBox >>> .el-form-item__content .el-form-item__error {
  left: 256px;
}
</style>