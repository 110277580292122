{<template>
  <div>
    <div class="bgBox">
      <el-carousel arrow="never" indicator-position="none">
        <el-carousel-item v-for="img in bglist" :key="img.id">
          <img class="img" :src="img.url" alt="" />
        </el-carousel-item>
      </el-carousel>
      <div class="bgTitle">
        <div>携手共进&nbsp;&nbsp;合作共赢</div>
        <div>全国各省市诚招合伙人，携手共促行业新时代</div>
      </div>
    </div>
    <div>
      <div class="mainBox">
        <div class="mainauto">
          <div>
            <p class="title">成为代理商/合伙人将获得</p>
            <p class="subtitle">
              我们有丰富优质的资源,致力于帮助合作伙伴获得业务实践和解决方案
            </p>
            <el-row type="flex" class="row-bg" justify="space-between">
              <el-col>
                <div class="grid-content bg-purple">
                  <div class="getImg">
                    <img
                      src="../../assets/aboutus/recruit-partners-img1.jpg"
                      alt=""
                    />
                    <img
                      src="../../assets/aboutus/recruit-partners-icon1.png"
                      alt=""
                    />
                  </div>

                  <div class="getTitle">品牌竞争力</div>
                  <div class="getMain">
                    成熟产品、品牌――专业的 SaaS 产品
                  </div>
                </div>
              </el-col>
              <el-col
                ><div class="grid-content bg-purple-light">
                  <div class="getImg">
                    <img
                      src="../../assets/aboutus/recruit-partners-img2.jpg"
                      alt=""
                    />
                    <img
                      src="../../assets/aboutus/recruit-partners-icon2.png"
                      alt=""
                    />
                  </div>
                  <div class="getTitle">利润丰厚</div>
                  <div class="getMain">
                    巨大市场、高额利润——让利合作伙伴、互惠互利、双方收益
                  </div>
                </div></el-col
              >
              <el-col
                ><div class="grid-content bg-purple">
                  <div class="getImg">
                    <img
                      src="../../assets/aboutus/recruit-partners-img3.jpg"
                      alt=""
                    />
                    <img
                      src="../../assets/aboutus/recruit-partners-icon3.png"
                      alt=""
                    />
                  </div>
                  <div class="getTitle">专业培训</div>
                  <div class="getMain">
                    全方面培训，第一时间安排1对1售前合作支持
                  </div>
                </div></el-col
              >
            </el-row>
          </div>
        </div>
        <div>
          <p class="title">我们期待这样的您加入</p>
          <div class="expectMain">
            <div class="mainauto">
              <div class="expectBox">
                <div class="expectMainBox">
                  <div class="expect-title">有企业客户资源</div>
                  <div class="main">
                    客户经理，销售，管理咨询顾问，企业培训，商协会、产业园区管理者
                  </div>
                  <span class="number">01</span>
                </div>
              </div>
               <div class="expectBox">
                <div class="expectMainBox">
                  <div class="expect-title">有IT、行政资源</div>
                  <div class="main">
                    公司IT，行政，财务人员、资产管理员，俱乐部、社群管理者
                  </div>
                  <span class="number">02</span>
                </div>
              </div>
               <div class="expectBox">
                <div class="expectMainBox">
                  <div class="expect-title">有销售网络</div>
                  <div class="main">
                    有自己客源或有意愿开拓市场的代理商、经销商及个人
                  </div>
                  <span class="number">03</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mainauto"><form-submit></form-submit></div>
        
      </div>
    </div>
  </div>
</template>

<script>
import FormSubmit from './FormSubmit';
export default {
  components: { FormSubmit },
  name: "recruit-partners",
  data() {
    return {
      bglist: [{ id: 0, url: require("@/assets/aboutus/freshtalent_bg1.jpg") }],
    };
  },
};
</script>

<style scoped>
.bgBox {
  width: 100%;
  position: relative;
}
.bgBox >>> .el-carousel__container {
  height: 700px;
}
.img {
  width: 100%;
  height: 100%;
}
.bgTitle {
  min-width: 700px;
  position: absolute;
  color: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  line-height: 50px;
  text-align: center;
}
.bgTitle > div:nth-child(1) {
  font-size: 48px;
  margin-bottom: 20px;
}
.bgTitle > div:nth-child(2) {
  font-size: 38px;
}
.mainBox {
  overflow: hidden;
  padding-bottom: 180px;
}
.mainauto {
  width: 1200px;
  margin: 0 auto;
}
.title {
  font-size: 38px;
  color: #404040;
  padding-top: 56px;
  text-align: center;
}
.subtitle {
  padding: 40px 0;
  font-size: 18px;
  color: #666;
  text-align: center;
}
.row-bg {
  margin: 70px 0;
}
.row-bg .grid-content {
  width: 99%;
  text-align: center;
}
.getImg {
  width: 100%;
  height: 136px;
  position: relative;
  margin-bottom: 36px;
}
.getImg img:nth-child(1) {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.getImg img:nth-child(2) {
  position: absolute;
  top: 50%;
  left: 36px;
  transform: translateY(-50%);
}
.getTitle {
  font-size: 24px;
  color: #383838;
  margin-bottom: 27px;
  font-weight: normal;
}
.getMain {
  font-size: 18px;
  color: #808080;
  line-height: 28px;
  padding: 0 42px;
}
.expectMain {
  width: 100vw;
  background-color: #f7fafe;
  padding:20px 0 10px;
  margin:56px 0;
}
.expectBox {
  width: 100%;
  position: relative;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 18px 54px rgb(240 242 244);
  margin-bottom: 20px;
}
.expectMainBox {
  padding: 50px 50px 50px 180px;
}
.expectMainBox .expect-title {
  font-size: 24px;
  color: #688fff;
  margin-bottom: 20px;
  display: block;
  font-weight: normal;

}
.expectMainBox .main {
  font-size: 18px;
  color: #808080;
}
.expectMainBox .number{
        font-size: 36px;
    color: #688FFF;
    font-weight: normal;
    position: absolute;
    top: 44px;
    left: 45px;
}
.expectMainBox .number::after{
        content: '';
    display: block;
    width: 1px;
    height: 100px;
    background: #D8E2FF;
    position: absolute;
    right: -6px;
    top: 13px;
    transform: rotate(30deg);
}
</style>